<template>
  <div>
    <Loading :msg="$i18n.t('games.room_join.connection')" />
  </div>
</template>

<script>
import Loading from "../../../components/loading/Loading.vue";
import {ROUTE_HOME, ROUTE_ROOM_ACCOUNT, ROUTE_ROOM_CONNECTION} from "../../../router";
import {isNull} from "../../../utils/isNull";
import {API} from "../../../utils/api";
import {UPDATE_ROOM_STATE} from "../../../store/modules/userModule/actions";
import {ROOM_STATUS_ON} from "../../../constants/rooms/RoomStatus";
import {TOAST_DEFAULT} from "../../../constants/toast/toastConstants";
import {GAME_CONFIGURATION} from "@/constants/games/GameStatusConstants";
export default {
  name: "RoomInvitation",
  components: {Loading},
  async mounted() {
    if (isNull(this.$route.query.pin)) {
      this.$toast.error(this.$i18n.t('room.connection.errors.connexionFailure').toString());
      await this.$router.push({name: ROUTE_HOME.name});
      return;
    }

    API.get('/room', {params: {pin: this.$route.query.pin, status: ROOM_STATUS_ON}})
      .catch(({response}) => {
        this.$toast.error(this.$i18n.t(`room.connection.errors.${response.data.code}`).toString(), TOAST_DEFAULT);
        this.$router.push({name: ROUTE_HOME.name});
      });

    // Reset room state in case it hasn't been done before
    await this.$store.dispatch(UPDATE_ROOM_STATE, null);

    if (this.$store.getters.isNotConnectedOrIsGuest) {
      await this.$router.push({name: ROUTE_ROOM_ACCOUNT.name, query: {pin: this.$route.query.pin, redirect: ROUTE_ROOM_CONNECTION.name}});
      return;
    }

    await this.$store.dispatch(UPDATE_ROOM_STATE, GAME_CONFIGURATION);
    await this.$router.push({name: ROUTE_ROOM_CONNECTION.name, query: {pin: this.$route.query.pin}});
  }
};
</script>
